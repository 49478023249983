<template>
  <div
    class="layout-container icon-block-slider py-40 md:py-56"
    :class="{
      'md:px-56': data.UseSliderInMobile,
    }"
  >
    <div
      v-if="data.Heading"
      class="md:heading-lg-text sub-heading-lg-text md:mb-12 mb-16"
      :class="{ 'text-center': config.theme === 'thunder' }"
    >
      {{ data.Heading }}
    </div>
    <WysiwygWrapper
      :style-config="{ 'md:preamble-thin-text base-tex': true }"
      :html="data.Subheading"
      :class="{ 'text-center': config.theme === 'thunder' }"
    ></WysiwygWrapper>
    <div v-if="data.UseSliderInMobile" class="-mx-24">
      <swiper
        class="iconblock-swiper"
        :slider-per-view="'auto'"
        :space-between="16"
        :breakpoints="{
          '1024': {
            slidesPerView: 'auto',
            spaceBetween: 0,
            simulateTouch: false,
            allowTouchMove: false
          },
        }"
      >
        <SwiperSlide
          v-for="(iconBlock, index) in data.IconItemBlocks"
          :key="index"
          class="swiper-slide text-base px-32 sm:mr-0"
          :class="{
            'bg-grey400 py-32': !data.InvertColors,
          }"
        >
          <IconItemBlock :block="iconBlock?.ContentLink?.Expanded" />
        </SwiperSlide>
      </swiper>
    </div>
    <div v-else class="-mx-24">
      <div class="md:flex w-auto px-24 md:justify-center p-32">
        <div
          v-for="(iconBlock, index) in data.IconItemBlocks"
          :key="index"
          class="px-16 w-full text-base sm:mb-56 mb-24 sm:px-32 md:mb-0 md:w-1/4 text-center "
          :class="{
            'bg-grey400 py-32 md:px-24': !data.InvertColors,
            'md:px-12 invert-colors': data.InvertColors,
            'icon-block': config.theme === 'thunder',
          }"
        >
          <IconItemBlock :block="iconBlock?.ContentLink?.Expanded" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type IconBlockFragment } from '#gql';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';
import IconItemBlock from '~/components/blocks/IconItemBlock.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

const config = useGlobalContentStore().config;

const props = defineProps<{
  block: IconBlockFragment,
  isMobile: boolean,
}>();

const data = computed(() => {
  return props.block as IconBlockFragment;
});
</script>
<style>

.iconblock-swiper {
  .swiper-container {
    @apply pr-32 md:pr-0;
  }
  .swiper-wrapper {
    @apply flex w-auto p-32 items-stretch;
  }
  
  .swiper-slide {
    @apply !w-4/5 sm:!w-2/5 md:!w-1/5 h-auto;
  }

}
</style>
